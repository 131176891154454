/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-irregular-whitespace */
import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { ThemeProvider } from 'styled-components';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons';

import theme from '../stylesheets/theme';

import Layout from '../components/layout';

const AboutUsStyles = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  .about-us-title {
    text-align: center;
    margin: 50px auto 25px;
    padding: 0 25px;
  }
  .about-us-header-image {
    width: 100%;
    max-height: 800px;
    max-width: 1400px;
    margin: 58px auto 0;
  }
  .about-us-content-container {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    margin: 15px auto;
    padding: 0 25px;
    .about-us-description {
      /* margin-bottom: 30px; */
    }
    .offering-img-row {
      width: 100%;
      max-width: 1200px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0 auto;
      .offering-img-vert {
        max-height: 100vh;
        max-height: 800px;
        /* height: 100%; */
        width: calc(50% - 12.5px);
        margin: 50px 0;
      }
    }
  }
`;

// eslint-disable-next-line react/prop-types
const AboutUs = ({ data }) => (
  <Layout
    seo={{
      title: 'Naisten retket',
      url: 'https://www.discoverlapland.fi/tietoa-meista',
      image: `https://www.discoverlapland.fi/${data.tietoaMeistaSEO.childImageSharp.gatsbyImageData.src}`,
      description: 'Tietoa meistä',
    }}
  >
    <ThemeProvider theme={theme}>
      <AboutUsStyles>
        <GatsbyImage
          image={data.tietoaMeista.childImageSharp.gatsbyImageData}
          className="about-us-header-image"
          alt="Tietoa meistä"
        />
        <h2 className="about-us-title">Discover Lapland</h2>
        <div className="about-us-content-container">
          <div className="about-us-description">
            <p>
              Discover Lapland järjestää Tunturi-Lapin ja Käsivarren alueella
              eksklusiivisia, pienryhmissä toteutettavia naisten retkiä ympäri
              vuoden. Tarjoamme opastettuja naisten vaellus-, hiihtovaellus-,
              maastopyöräily-, sekä vapaalaskuretkiä kauniissa ja
              ainutlaatuisessa Suomen Lapissa.
              <br />
              <br />
              Discover Lapland on suomalainen yritys, jonka kotipaikka on
              Levillä. Luonnolla on positiivinen vaikutus terveyteen ja
              hyvinvointiin ja mikä onkaan parempi paikka kokea luontoäidin
              voima kuin Lappi. Oppaamme ja ohjaajamme ovat kunkin aktiviteetin
              koulutettuja alan ammattilaisia. Meille tärkeää on antaa paras
              mahdollinen asiakaskokemus ennen retkeä, retkellä ja sen jälkeen.
              <br />
              <br />
              <span className="bolded">Arvoihimme kuuluvat:</span> <br />
              Yhteisöllisyys <br />
              Luonto <br />
              Terveelliset elämäntavat <br />
              Kestävä kehitys ja vastuullisuus <br />
              Uuden oppiminen <br />
              Positiivinen energia <br />
              <br />
            </p>
            <div className="offering-img-row">
              <GatsbyImage
                image={data.anette1.childImageSharp.gatsbyImageData}
                className="offering-img-vert"
                alt="image"
              />
              <GatsbyImage
                image={data.anette2.childImageSharp.gatsbyImageData}
                className="offering-img-vert"
                alt="image"
              />
            </div>
            <p>
              <span className="bolded">
                Yrityksen perustaja Anette Latva-Piikkilä ja Discover Laplandin
                synty
              </span>
              <br />
              Discover Laplandin perustaja on Anette Latva-Piikkilä. Hän muutti
              Lappiin vuonna 2016, jonka kaunis ympäristö tarjoaa kaiken, mitä
              hän rakastaa tehdä Lapin kahdeksan eri vuodenajan aikana. Anette
              on luontoelämysohjaaja, tutkinnon hän suoritti Rovaniemen Santa
              Sport -koulussa.
              <br />
              <br />
              <span className="slanted">
                ”Sain lentävän lähdön tähän maailmaan, kun meinasin syntyä Ladan
                takapenkille Helsinki- Hyvinkää välillä. Äitini onnistui
                pidättelemään minua Hyvinkäälle asti, missä synnyin vuonna 1979.
                <br />
                <br />
                Pienenä leikin usein itsekseni, koska muut tytöt eivät
                leikkineet samoja leikkejä. Joskus pojat ottivat minut
                leikkeihinsä mukaan. Äitini sai varmasti pysyvän rasitusvamman
                käteensä joutuessaan heittämään palloa, pelaamaan tennistä tai
                sulkapalloa kanssani. Hän usein muistuttaa minua siitä, kuinka
                aktiivinen olin pienenä, täynnä energiaa.
                <br />
                <br />
                Koulussa en oikein loistanut, koska mikään tieto ei tuntunut
                tarttuvat päähän. Tykkäsin kovasti liikunnasta sekä käsitöistä
                ja näissä aineissa sainkin muutaman stipendin.
                <br />
                <br />
                Teininä harrastin paljon eri lajeja ja karting vei lopuksi
                leijonan osan ajastani. Välillä toki harmitti, kun kaverit
                lähtivät viettämään viikonloppua yhdessä ja minä suuntasin isäni
                kanssa Pieksämäen soramonttuun ajamaan kisaa. Tuntui, että jäin
                jostain paitsi, paitsi, että en jäänyt, vaan keräsin hyvin
                erilaista kokemusta ja oppia elämästä kuin muut ikäiseni.
                <br />
                <br />
                Minut kasvatettiin yksinyrittäjä isäni puolesta ajatukseen, että
                mikä vain on mahdollista. Vahvoja luonteenpiirteitäni ovat aina
                olleet rohkeus, positiivisuus ja olen hyvällä tavalla naiivi. En
                myöskään suuresti välitä muiden mielipiteistä, vaan kuuntelen
                itseäni ja uskon tekemiseeni. Olen myös hyvin lojaali ja
                rohkaisen muita heidän tiellään.
                <br />
                <br />
                Yläasteella yrittäjähenkisyyteni sai ensipuraisun, kun keräsin
                yhteistyökumppaneita karting-harrastukseeni, joka oli hyvin
                kallista. Tein erilaisia markkinointitoimenpiteitä ja
                sopimuksia. Hoidin myös itse omat ajosopimukseni kansainvälisten
                tiimien kanssa, sillä isäni ei puhunut englantia. Olin hyvin
                toimelias ja innostuin näistä haasteista. Sain kiertää Eurooppaa
                pakettiautolla isäni kanssa ja myöhemmin lensin MM- ja
                EM-kisoihin, joissa olin yksin, vain ulkomaalainen mekaanikko
                tukenani. Upeita aikoja, hyvin opettavaista.
                <br />
                <br />
                Hypätään teini-iästä aikuisuuteen. Minusta ei siis tullut
                seuraavaa naisformulakuskia, vaan ohjelmapalveluyrittäjä.
                <br />
                <br />
                Vuonna 2016 muutimme Lappiin, koska halusimme päästä
                harrastamaan luonnossa ympäri vuoden eri aktiviteettejä. Vuoden
                verran kolusinkin tuntureita, polkuja ja upeita paikkoja, ottaen
                usein mukaan ystäviä ja perhettäni. Halusin heidän kokevan ja
                näkevän paikan kauneuden ja eri lajit.
                <br />
                <br />
                Mietin silloin myös, että mitä alkaisin seuraavaksi tekemään?
                Olin ollut yrittäjänä jo vuodesta 2008, joten hain viestintä- ja
                markkinointitekemiseen jatketta. Jotain, jolla olisi suurempi
                vaikutus tässä yhteiskunnassa ja joka toisi hyvää ihmisten
                elämään. Yhtenä pimeänä kaamosiltana tajusin, että kädessäni
                loisti jo jotain ja pidin tätä timanttia kädessäni. Minähän teen
                jo sitä mistä tykkään, naisten retkiä Lapissa! Tästä syntyi
                Discover Lapland.
                <br />
                <br />
                Retkeni pohjautuvat siihen, että naiset ovat unelmoineet
                tekevänsä jotain tarjoamistamme aktiviteetistä Lapissa. He eivät
                uskalla lähteä yksin retkeä tekemään tai kokeilemaan lajia,
                heillä ei välttämättä ole ystäviä, joiden kanssa tekisi tai
                eivät vain ole saaneet aikaan järjestää retkeä itse.
                <br />
                <br />
                Jo ensimmäisestä kaupallisesta retkestä vuonna 2017 huomasin,
                että konseptini toimii. Asiakaspalaute minkä sain, avasi silmäni
                vielä entisestään: Retket ovat olleet syvällisempiä ja
                voimaannuttavampia kokemuksia, mitä aluksi uskoin niiden olevan.
                Vaikka tarjoamme retkillä vain opastuksen, osallistuja käy
                retkellä läpi omia tunteitaan, tuntemuksiaan ja herättelevät
                heitä ymmärtämään itseään paremmin. Ja luovat uskoa heihin
                itseensä; minä pystyn mihin vain! Meidän retkiltä valmistuu
                Supernaisia!
                <br />
                <br />
                On ollut tärkeää löytää oikeat kumppanit kenen kanssa toimia.
                Teen yhteistyötä ihmisten ja yritysten kanssa, jotka jakavat
                saman arvomaailman: Yhteisöllisyys, luonto, terveelliset
                elämäntavat, kestävä kehitys ja vastuullisuus, uuden oppiminen
                ja positiivinen energia.
                <br />
                <br />
                Retkillämme kaikki saavat olla sitä mitä he haluavat olla.
                Luonto riisuu ihmisistä monta kerrosta. Egot jäävät pois ja alta
                paljastuu sinä.
                <br />
                <br />
                Luonto kuuluu meille kaikille ja meidän tulee elää sovussa sen
                kanssa, kestävällä tavalla. Tuomalla osallistujan lähemmäksi
                luontoa, näyttämällä sen kauden, paikallisen kulttuurin ja tavan
                toimia retkillä vastuullisesti on osa meidän tapaamme huolehtia
                tästä planeetasta. Yksi askel kerrallaan kohti parempaa maailmaa
                ja kestävää luontomatkailua.”
              </span>
              <br />
              <br />
              <span className="bolded">Vastuullisuus ja kestävä kehitys</span>
              <br />
              Discover Lapland on tehnyt vuosien ajan tiivistä yhteistyötä
              Pallas-Yllästunturin sekä Kilpisjärven kansallispuistojen kanssa.
              Vastuullisuus on vahva arvo toiminnassamme ja luonto
              toimintaympäristömme. Aktiviteettimme ovat lihasvoimalla
              toteutettuja ja haluamme antaa osallistujalle hyvän pohjan sekä
              luonnon- että kansallispuistossa liikkumiseen. Teemme myös
              kiinteää yhteistyössä paikallisten yritysten kanssa ja
              palveluketjussamme on kestävään luontomatkailuun sitoutuneita
              toimijoita.
              <br />
              <br />
              Discover Lapland oli ensimmäinen yritys maailmassa, joka sai Green
              Activities ympäristösertifikaatin. Missiomme ja tavoitteemme on
              pitkäjänteinen sitoutuminen vastuullisuuteen, oman toiminnan
              ympäristövastuullisuuden arviointi ja kehittäminen sekä
              asiakkaiden osallistaminen ja heidän tietoisuutensa kasvattaminen.
              <br />
              <br />
              <span className="slanted bolded">
                Tule voimaantumaan kanssamme Lapin luontoon, tapaamaan
                samanhenkisiä upeita naisia, oppimaan uusia taitoja sekä ehkäpä
                jotain uutta itsestäsi.
              </span>
            </p>
          </div>
        </div>
      </AboutUsStyles>
    </ThemeProvider>
  </Layout>
);

export const query = graphql`
  {
    tietoaMeista: file(relativePath: { eq: "images/mtb/pahakuru-9.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    anette1: file(relativePath: { eq: "images/anette-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    anette2: file(relativePath: { eq: "images/anette-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    tietoaMeistaSEO: file(relativePath: { eq: "images/mtb/pahakuru-9.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1366, layout: FIXED)
      }
    }
  }
`;

export default AboutUs;
